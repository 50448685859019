<template>
    <div>
        <top-nav></top-nav>

        <div class="content mt-5 pa-4 text-left">
            <h2 class="secondary--text">Reglas</h2>

            <ul style="font-size:13px" class="mt-4 grey--text">
                <li>Ser puntual a la hora de su sección de entrenamiento. (15 minutos de gracia, de llegar tarde tendrá que esperar la próxima sección)</li>
                <li>Debe traer spray de alcohol desinfectante y mascarilla.</li>
                <li>Traer su mat de ejercicio.</li>
                <li>Petus ONLINE tienen que enviar video de 1 minuto mínimo al petuphone para evidenciar que haya hecho rutina diaria.</li>
                <li>Reservaciones de entrenamientos online y presencial se realizan a través de la página de PetuPower.fitness.</li>
                <li>Créditos de entrenamientos sólo se realizan por cancelaciones realizadas por la entrenadora Kenialyz o situaciones de emergencias con evidencia.</li>
                <li>Reglas aplican a todas, NO hay excepción de persona.</li>
            </ul>

            <h2 class="secondary--text mt-3">Cancelaciones</h2>

            <ul style="font-size:13px" class="mt-4 grey--text">
                <li>Si quieres cancelar tu reservación puedes hacerlo con 2 días de anticipación. No se hace devolución de dinero, se dará crédito de entrenamiento.</li>
            </ul>

            <h2 class="secondary--text mt-3">Strikes</h2>

            <ul style="font-size:13px" class="mt-4 grey--text">
                <li>Petu online deben enviar video de al menos 1 minuto para evidenciar la rutina. De no enviar video, contará como un strike.</li>
                <li>Petu presencial que se ausente a entrenamiento 3 veces tendrá un strike.</li>
                <li>Petu presencial debe firmar en la asistencia diaria que tendrá Kenia para así saber que fue a entrenar. Es su responsabilidad firmar.</li>
                <li>Los strikes se darán semanales.</li>
                <li>Un strike será eliminado si tienes asistencia perfecta por dos semanas corridas.</li>
                <li>Día para reponer cualquier rutina de la semana es Sábado.</li>
                <li>Esto aplica a todas. NO hay excepción de persona.</li>
            </ul>
        </div>
        
        <bottom-nav></bottom-nav>
    </div>
</template>

<script>
    import TopNav from '../components/TopNav.vue'
    import BottomNav from '../components/BottomNav'

    export default {
        components: { TopNav, BottomNav },
    }
</script>

<style lang="scss" scoped>
.content {
    margin-bottom: 70px;
}
.content h2 {
    font-size: 20px;
    font-weight: 500;
    // font-family: 'Roboto';
}
</style>